import React from 'react';
import { graphql, Link } from 'gatsby';

import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Media from 'react-media';
import MediaImage from '../components/images/mediaImage';

import LazyLoad from 'react-lazyload';
import Helmet from 'react-helmet';

const Event = styled.div`
  margin-bottom: 100px;
  text-align: center;

  & > a {
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const PublicationTitle = styled.h1`
  font-size: 28px;
  text-align: center;

  margin-top: 75px;
  margin-bottom: 144px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    margin-top: 0;
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const EventTitle = styled.div`
  text-align: center;

  color: #000;

  padding: 30px 0 0 0;
  span {
    color: red;
  }
  font-size: 28px;
  & h1 {
    display: inline;
  }
  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const Arrow = styled.button`
  display: block;
  text-align: center;

  position: sticky;
  top: 107px;
  left: 0;

  flex: 0 0 16.666667%;
  max-width: 16.666667%;

  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  border: 0;
  background-color: rgba(255, 255, 255, 0);

  cursor: pointer;

  z-index: 10;

  & > div {
    width: 65px;
    margin: 0 0 0 auto;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }

  & img {
    transform: rotateZ(${(props) => props.rotation});
    transform-origin: center;

    margin: 0 auto;

    width: 50px;
    height: auto;

    display: inline-block;
  }

  &:focus {
    outline: none;
  }

  & + div > h2.year {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    position: relative;

    top: auto;
    left: auto;

    margin: 0 auto;

    & img {
      width: 50px;
    }
  }
`;

const Year = styled.h2`
  text-align: center;
  margin-top: ${(props) => props.marginTop};

  @media (max-width: 767px) {
    text-align: center;
    margin: 0 auto 30px auto;
  }
`;

class Archive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listItems: this.mergeItems(
        this.props.data.allPrismicEvent.edges,
        this.props.data.allPrismicNews.edges
      ),
      arrow: true,
    };

    this.reorderArchive = this.reorderArchive.bind(this);
  }

  mergeItems(events, news) {
    const mergedItems = [...events, ...news];
    mergedItems.sort(
      (a, b) => new Date(b.node.data.date) - new Date(a.node.data.date)
    );
    return mergedItems;
  }

  reorderArchive() {
    let reOrderAllYears = this.state.listItems.reverse();

    this.setState((prevState) => ({
      listItems: reOrderAllYears,
      arrow: prevState.arrow === false ? true : false,
    }));
  }

  render() {
    const allArchiveItems = this.state.listItems.map((item, index) => {
      return (
        <LazyLoad height={400} key={index}>
          <Col col={12} sm={6}>
            <Event marginTop={index === 0 ? `-47px` : '0'}>
              <Link to={'/archive/' + item.node.uid}>
                <MediaImage image={item.node.data.image.url} />
                <EventTitle>
                  <h2> {item.node.data.title.text}</h2>
                </EventTitle>
              </Link>
            </Event>
          </Col>
        </LazyLoad>
      );
    });

    return (
      <>
        <Helmet title={`Archive - Askeaton Contemporary Arts`} />
        <Col col={12} md={10}>
          <PublicationTitle>{'Archive'}</PublicationTitle>
          <Row>{allArchiveItems}</Row>
        </Col>
      </>
    );
  }
}

export const query = graphql`
  query ArchiveItems {
    allPrismicEvent(
      filter: { data: { display_in_current: { ne: "True" } } }
      sort: { order: DESC, fields: [data___archive_date] }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date
            archive_year
            display_in_current
            location
            image {
              dimensions {
                width
                height
              }
              url
            }
            archive_date(formatString: "YYYY")
          }
        }
      }
    }
    allPrismicNews(
      filter: { data: { display_in_current: { ne: "True" } } }
      sort: { order: DESC, fields: [data___date] }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date
            display_in_current
            image {
              dimensions {
                width
                height
              }
              url
            }
          }
        }
      }
    }
  }
`;

export default Archive;
