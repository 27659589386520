import React from 'react';
import styled from 'styled-components';

const MediaImageContainer = styled.div`
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.66%;
  }
`;

const Media = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const MediaImage = props => (
  <MediaImageContainer>
    <Media src={props.image} />
  </MediaImageContainer>
);
export default MediaImage;
